import React from 'react';

const PrimeDirectives = () => {
  const directives = [
    "Serve the public trust.",
    "Protect the innocent.",
    "Uphold the law.",
    "<<Classified>>"
  ];

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Prime Directives</h2>
      <ul>
        {directives.map((directive, index) => (
          <li key={index}>{directive}</li>
        ))}
      </ul>
    </div>
  );
};

export default PrimeDirectives;

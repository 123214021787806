import React from 'react';

function Contact() {
  return (
    <section id="contact" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Contact</h2>
      <p>Email: hello@patrick.co.ke</p>
      <h3>GPG Public Key</h3>
      <p>
        You can download my GPG public key from 
        <a href="/gpg-key.asc" target="_blank" rel="noopener noreferrer">
           this link
        </a>.
      </p>
    </section>
  );
}

export default Contact;


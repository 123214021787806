import React from 'react';

function Projects() {
  return (
    <section id="projects" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Projects</h2>
      <ul>
        <li><a href="https://zerxis.com" target="_blank" rel="noopener noreferrer">Zerxis Limited</a></li>
        <li><a href="https://callcenter.africa" target="_blank" rel="noopener noreferrer">Call Center Africa</a></li>
        <li><a href="https://vii.ke" target="_blank" rel="noopener noreferrer">VII</a></li>
      </ul>
    </section>
  );
}

export default Projects;

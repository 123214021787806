import React from 'react';
import './styles/styles.css';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import PrimeDirectives from './components/PrimeDirectives';
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <div>
      <Header />
      <main>
        <About />
        <Projects />
        <PrimeDirectives />
        <Contact />
      </main>
      <Footer />
      <SpeedInsights />
    </div>
  );
}

export default App;

